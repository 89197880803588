import React from 'react'
import { Link } from 'gatsby'

import Plus from '../../../images/svgs/plus.svg'
import Minus from '../../../images/svgs/minus.svg'


import { CartContext } from '../../../components/shared/CartProvider'


const IngredientTile: React.FC = (data) => {
  const { ingredient, recipes, changeCallback } = data;

  const removeFromCart = (cart, ingredient) => {
    cart.subtract(ingredient.sku)
  }

  const addToCart = (cart, ingredient) => {
    cart.add(ingredient.sku)
  }

  const addRecipeToCart = (cart, recipes) => {
    recipes?.forEach((recipe) => {
      cart.addRecipe(recipe.slug)
    })
  }

  return (
    <CartContext.Consumer key={`ingredient-cartconsumer-${ingredient.sku}`}>
      {(cart) => {
        const inCart = cart.contains(ingredient)
        const count = cart.itemCount(ingredient)
        const removeClasses = inCart ? "fill-secondary cursor-pointer" : "fill-grey"

        return (
          <React.Fragment key={`sellable-ingredient-${ingredient.sku}`}>
            <div className="col-12 col-sm-6 col-xl-4 px-2 mb-3">
              <div className="border shadow-sm rounded d-flex p-2 h-100p bg-white">
                <div className="col-4 px-0 my-auto text-center">
                  <div>
                    <div className="minus remove-from-cart d-inline" key={`ingredient-minus-${ingredient.sku}`}>
                      <Minus className={`${removeClasses} w35p remove-from-cart`} onClick={() => {
                        window.dataLayer?.push({ event: 'Remove From Cart' });
                        if (changeCallback) {
                          changeCallback(ingredient, 'remove')
                        }
                        removeFromCart(cart, ingredient)
                      }} />
                    </div>
                    <div className="d-inline mx-2">
                      {count}
                    </div>
                    <div className="plus add-to-cart d-inline" key={`ingredient-plus-${ingredient.sku}`}>
                      <Plus className="fill-primary w35p cursor-pointer add-to-cart" onClick={() => {
                        window.dataLayer?.push({ event: 'Add To Cart' });
                        if (changeCallback) {
                          changeCallback(ingredient, 'add')
                        }
                        addToCart(cart, ingredient)
                        addRecipeToCart(cart, recipes)
                      }} />
                    </div>
                  </div>
                  {ingredient.amount != null && <div>
                    <p className="smaller pt-2">{Math.ceil(ingredient.amount)} Needed</p>
                  </div>
                  }
                </div>
                <div className="col-5 px-0 d-flex flex-column">
                  <p className="pl-3 lh-100 small mb-0">
                    <Link to={`/products/${ingredient.slug}`} className="text-body">
                      <span>{ingredient.name}</span>
                    </Link>
                  </p>
                  <p className="smaller pl-3 text-black-50 mb-0 pt-1" >
                    <span>${(ingredient.priceCents / 100).toFixed(2)}</span>
                    {ingredient.quantityDescription &&
                      ` - ${ingredient.quantityDescription}`}
                  </p>
                  <p className="smaller pl-3 text-black-50 pt-1 mb-1 lh-100 text-single-line">{ingredient.brand}</p>
                </div>
                <div className="col-3 bg-grey px-0 bg-light d-flex mr-2 full-height-right rounded-right">
                  <Link to={`/products/${ingredient.slug}`} className="w-100 d-flex">
                    <img className="img-fluid mh-75p mx-auto my-auto" src={ingredient.picture?.fluid?.src} />
                  </Link>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      }}
    </CartContext.Consumer >
  )
}

export default IngredientTile
