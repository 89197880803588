import React from 'react'
import IngredientTile from '../IngredientTile'
import { ingredientsBySection } from '../../../common/sections'

const SectionedIngredients = ({ ingredients }) => {
  const sectionedIngredients = ingredientsBySection(ingredients)

  return (
    <>
      {sectionedIngredients.map((section, sectionIndex) => {
        return (
          <React.Fragment key={`section-${section.sectionName}-${sectionIndex}`}>
            <div className="row mt-3">
              <div className="col-12">
                <h4 className="font-weight-bold">{section.sectionName}</h4>
              </div>
            </div>
            <div className="row">
              {section.ingredients.map((ingredient) => {
                return (
                  <IngredientTile ingredient={ingredient} recipes={ingredient.recipes} key={`ingredient-tile-${ingredient.sku}`} />
                )
              })}
            </div>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default SectionedIngredients
