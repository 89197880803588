import React, { useState } from 'react'
import { graphql } from 'gatsby'
import SectionedIngredients from '../components/shared/SectionedIngredients'
import RecipeDetails from '../components/shared/Recipes/Details'
import { getAllIngredients } from '../common/ingredients'
import { DeferRender } from '../components/shared/DeferRender'
import { CartContext } from '../components/shared/CartProvider'
import WithLocation from '../components/shared/WithLocation'

import IndexLayout from '../layouts'

const ShopRecipes = (props) => {
  const data = props.data

  const displayedRecipes = data.allContentfulRecipe.nodes.filter((recipe) => {
    return props.search.recipes && props.search.recipes.split(",").indexOf(recipe.slug) != -1
  })

  const [filterRecipe, setFilterRecipe] = useState(null)


  const setFilter = (recipe) => {
    if (recipe == filterRecipe) {
      setFilterRecipe(null)
    } else {
      setFilterRecipe(recipe)
    }
  }

  const allIngredients = getAllIngredients(displayedRecipes, filterRecipe)

  return (
    <IndexLayout>
      <DeferRender>
        <div className="container">
          <div className="row py-2 mb-5 pt-5">
            <div className="col-12">
              <h1>Shop Ingredients</h1>
              <h4 className="t">
                Everything you need to make {displayedRecipes.length == 1 ? "this recipe" : `these ${displayedRecipes.length} recipes`}.
                Add it all to cart or select what you need below.
              </h4>
            </div>
          </div>
        </div>
        <section id="recipes">
          <div className="container">
            <div className="row">
              {displayedRecipes.map((recipe, index) => (
                <React.Fragment key={`recipes-${recipe.slug}-${index}`}>
                  <RecipeDetails recipe={recipe} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
        <section id="shoppageIngredients" className="container pt-5">
          <div className="row border-bottom pb-3">
            <div className="col-12">
              <h5>Filter by recipe</h5>
            </div>
          </div>
          <div className="row scroll-horizontal-md">
            {displayedRecipes.map((recipe, index) => {
              const selected = filterRecipe === recipe

              return (
                <div className={`cursor-pointer col-5 col-sm-3 col-xl-2 rounded py-2 text-center ${selected ? 'bg-dark' : ''}`} key={`filter-recipe-${index}`} onClick={() => {
                  setFilter(recipe)
                }}>
                  <img className="img-fluid" src={recipe.rectangularImage?.fluid?.src} alt="The recipe" />
                  <div style={{ height: "60px" }}>
                    <div className="small smaller-only-sm font-weight-bold pt-1 white-space-wrap limit-lines-two">{recipe.name}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <SectionedIngredients ingredients={allIngredients} />
          <CartContext.Consumer>
            {(cart) => (
              <div className="row pt-4">
                <div className="col-8 col-md-4 mx-auto text-center">
                  <button className="btn btn-primary btn-block" onClick={cart.toggleCart}>View Cart</button>
                </div>
              </div>
            )}
          </CartContext.Consumer>
        </section>
      </DeferRender>
    </IndexLayout >
  )
}

export const query = graphql`
  query ShopRecipesQuery {
    site {
      siteMetadata {
        description
      }
    }
    allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
      nodes {
        name
        slug
        homepageDescription {
          childMarkdownRemark {
            html
          }
        }
        rectangularImage {
          fluid(quality: 100, maxWidth:600) {
            src
          }
        }
        ingredients {
          amount
          ingredient {
            name
            sku
            priceCents
            id
            section
            quantityDescription
            brand
            picture {
              fluid(quality: 100, maxWidth:150) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default WithLocation(ShopRecipes)
