export const sections = ['Pantry', 'Produce', 'Spices', 'Fridge', 'Sauces']

export const ingredientsBySection = (ingredients: any[]) => {
  const addedIngredients = []

  const populatedSections = sections.map((sectionName) => {
    return {
      sectionName: sectionName,
      ingredients: ingredients ? ingredients.filter((ingredient) => {
        const shouldAdd = ((ingredient && ingredient.section) || (ingredient?.ingredient && ingredient.ingredient.section)) == sectionName
        if (shouldAdd) {
          addedIngredients.push(ingredient)
        }

        return shouldAdd
      }) : []
    }
  })

  const otherIngredients = ingredients.filter(x => !addedIngredients.includes(x))

  if (otherIngredients.length > 0) {
    populatedSections.push({
      sectionName: "Other",
      ingredients: otherIngredients
    })
  }

  return populatedSections.filter((section) => {
    return section.ingredients.length > 0
  })
}
