import React, { useState } from 'react'
import { CartContext } from '../../CartProvider'

const addRecipesToCart = (cart, recipes) => {
  recipes.forEach((recipe) => {
    cart.addRecipe(recipe.slug)

    recipe.ingredients.forEach((ingredient) => {
      const sellableIngredient = ingredient.ingredient

      if (!sellableIngredient) {
        return
      }

      if (sellableIngredient.priceCents && sellableIngredient.sku) {
        console.log(`SKU: ${sellableIngredient.sku}`)
        cart.add(sellableIngredient.sku, ingredient.amount || 0.01)
      }
    })
  })
  cart.toggleCart()
}

const removeRecipesFromCart = (cart, recipes) => {
  recipes.forEach((recipe) => {
    recipe.ingredients.forEach((ingredient) => {
      const sellableIngredient = ingredient.ingredient

      if (!sellableIngredient) {
        return
      }

      if (sellableIngredient.priceCents && sellableIngredient.sku) {
        console.log(`SKU: ${sellableIngredient.sku}`)
        cart.subtract(sellableIngredient.sku, ingredient.amount || 0.01)
      }
    })
  })
}

const RecipeAddButton: React.FC = ({ recipe }) => {
  let recipes = recipe
  if (!Array.isArray(recipes)) {
    recipes = [recipes];
  }

  const [addedToCart, setAddedToCart] = useState(false)

  return (
    <CartContext.Consumer>
      {(cart) => {
        if (addedToCart) {
          return (
            <button
              className="btn-lg btn-secondary full-width-bottom rounded-bottom add-to-cart mt-auto"
              onClick={() => {
                setAddedToCart(false)
                removeRecipesFromCart(cart, recipes)
              }}
            >
              Remove
            </button>
          )

        } else {
          return (
            <button
              className="btn-lg btn-primary full-width-bottom rounded-bottom add-to-cart mt-auto"
              onClick={() => {
                setAddedToCart(true)
                addRecipesToCart(cart, recipes)
              }}
            >
              Add all ingredients to cart
            </button>
          )
        }
      }}
    </CartContext.Consumer>
  )
}

export default RecipeAddButton
