const uniqueCount = (array) => {
  const map = {}
  for (const item of array) {
    if (map[item.id] != undefined) {
      map[item.id].amount = item.amount + map[item.id].amount
      map[item.id].recipes.push(item.originalRecipe)
    } else {
      map[item.id] = item
    }
  }

  return Object.entries(map).map((a) => { return a[1]; })
}


const getAllIngredients = (recipes, filterRecipe) => {
  let filterableRecipes = recipes
  if (filterRecipe) {
    filterableRecipes = [filterRecipe]
  }

  const allShoppableIngredients = filterableRecipes.map((recipe) => {
    return recipe.ingredients.filter((obj) => {
      return obj && obj.ingredient != null && obj.ingredient.priceCents && obj.ingredient.sku
    }).map((ingredient) => {
      return Object.assign(ingredient.ingredient, { originalRecipe: recipe, recipes: [recipe], amount: ingredient.amount || 0.01 })
    })
  }).flat()

  return uniqueCount(allShoppableIngredients)
}

export {
  getAllIngredients
}
