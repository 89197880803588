import React from 'react'
import AddButton from '../AddButton'
import { Link } from 'gatsby'

const RecipeDetails: React.FC = (props) => {
  const { recipe } = props

  return (
    <div className="col-12 col-md-4 px-2 mb-3" >
      <div className="border rounded p-3 h-100 position-relative mb-5 d-flex flex-column">
        <img className="img-fluid mh-100" src={recipe.rectangularImage?.fluid?.src} alt="The recipe" />
        <h5 className="mt-2 text-left">{recipe.name}</h5>
        <Link
          to={`/recipes/${recipe.slug}`}
          className='smaller'
        >View recipe</Link>
        <AddButton recipe={recipe} />
      </div>
    </div >
  )
}

export default RecipeDetails
